import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'subject',
    'alert',
    'content',
    'submit',
    'kit',
    'kitContainer',
  ];

  connect() {
    this.submitTarget.disabled = true;
  }

  click(event) {
    const subject = event.currentTarget.value;

    if (subject === 'takuhai') {
      this.alertTarget.classList.add('show-block');
    } else {
      this.alertTarget.classList.remove('show-block');
    }

    this.kitDisabledOnTakuhai();

    const haveContent = this.contentTarget.value.length > 0;
    this.submitTarget.disabled = !haveContent;
  }

  input(event) {
    const haveContent = event.currentTarget.value.length > 0;
    const haveSubject =
      this.subjectTargets.filter((el) => el.checked).length > 0;
    this.submitTarget.disabled = !(haveContent && haveSubject);
  }

  kitDisabledOnTakuhai() {
    this.kitTargets.forEach((select) => {
      select.disabled = this.subjectTargets[0].checked;
      select.selectedIndex = 0; // reset selected value
    });
  }
}
