import { Controller } from 'stimulus';
import 'blueimp-canvas-to-blob';
import DirectUploader from '../lib/direct_uploader';
import { loadImageAsync, drawResizedImageToCanvas } from '../lib/image';

export default class extends Controller {
  static targets = [
    'originInput',
    'input',
    'alert',
    'canvas',
    'fileInput',
    'clearButton',
  ];

  originInputTargetConnected(el) {
    this.fileInputTargets.forEach((input) => {
      input.name = el.name;
    });
    this.clearPreview();
  }

  change(event) {
    this.clearInput();
    const files = Array.from(event.target.files);

    if (files.length === 0) return;

    if (files.length > 3) {
      this.alertTarget.classList.remove('d-none');
      return;
    } else {
      this.alertTarget.classList.add('d-none');
    }

    this.clearButtonTarget.classList.remove('d-none');

    try {
      files.forEach(async (file, index) => {
        if (file.type.includes('image')) {
          await this.uploadImage(file, index);
        } else {
          await this.upload(file, index);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  clear() {
    this.clearPreview();
    this.alertTarget.classList.add('d-none');
    this.clearButtonTarget.classList.add('d-none');
    this.inputTarget.value = '';
  }

  async uploadImage(file, index) {
    const image = await loadImageAsync(URL.createObjectURL(file));
    const resizedCanvas = drawResizedImageToCanvas(
      this.canvasTargets[index],
      image
    );
    resizedCanvas.toBlob(
      async (blob) => {
        blob.name = `${new Date().getTime()}.jpg`;
        await this.upload(blob, index);
      },
      'image/jpeg',
      0.95
    );
    this.canvasTargets[index].classList.remove('d-none');
  }

  async upload(blob, index) {
    const uploadUrl = this.originInputTarget.dataset.directUploadUrl;
    const uploader = new DirectUploader(blob, uploadUrl);
    const result = await uploader.upload();
    this.fileInputTargets[index].value = result.signed_id;
  }

  clearPreview() {
    this.fileInputTargets.forEach((input) => {
      input.value = '';
    });
    this.canvasTargets.forEach((canvas) => {
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
      canvas.classList.add('d-none');
    });
  }
}
